"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonitorsTable = void 0;
require("./MonitorsTable.scss");
const applications_1 = require("@ui/applications");
const selectors_1 = require("@lib/selectors");
const MonitorsTableColumns_1 = require("./MonitorsTableColumns");
const text_1 = require("@ui/text");
const filters_1 = require("@dictionary/filters");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_core_1 = require("@sonar-software/sonar-core");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const antd_1 = require("antd");
const MonitorTypeTag_1 = require("./MonitorTypeTag");
const PaginatedTable_1 = require("@ui/tables/PaginatedTable");
const react_1 = __importStar(require("react"));
const general_1 = require("@ui/general");
const _hooks_1 = require("@hooks");
const getRowKey = (result) => result.id;
const columns = (0, MonitorsTableColumns_1.getColumns)();
const sortOptions = [
    {
        direction: sonar_ts_types_1.SonarV3SortDirection.Ascending,
        key: 'name',
        label: sonar_ts_constants_1.COPY_SPECIFIC.NAME
    },
    {
        direction: sonar_ts_types_1.SonarV3SortDirection.Ascending,
        key: 'monitorTypeId',
        label: sonar_ts_constants_1.COPY_SPECIFIC.TYPE
    },
    {
        direction: sonar_ts_types_1.SonarV3SortDirection.Ascending,
        key: 'applicationId',
        label: sonar_ts_constants_1.COPY_SPECIFIC.APP
    }
];
const pulseMonitorsSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.pulse.monitors.get);
const monitorTypeOptions = (0, sonar_ts_lib_1.enumNumericValues)(sonar_core_1.PulseMonitorType)
    .filter((type) => !(MonitorTypeTag_1.monitorParentType[type] === sonar_core_1.PulseMonitorType.Personal))
    .map((value) => ({
    label: react_1.default.createElement(MonitorTypeTag_1.MonitorTypeTag, { monitorTypeId: value }),
    value
}));
const convertToDql = (params) => (0, sonar_ts_lib_1.queryParamsToDynamicQueryLanguageParams)(params, {
    filterParams: {
        applicationId: true,
        deleted: true,
        name: { operator: sonar_ts_types_1.DynamicQueryOperator.Like },
        typeIds: {
            name: 'monitorTypeId',
            operator: sonar_ts_types_1.DynamicQueryOperator.In
        }
    }
});
const MonitorsTable = () => {
    const getPulseMonitors = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCorePulseMonitors);
    const deleteMonitor = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestDeleteCorePulseApplicationMonitorsById);
    const { data, isRequested } = (0, _hooks_1.useAsyncState)(pulseMonitorsSelectors);
    const { queryParams, updateParams } = (0, _hooks_1.useQueryParams)({
        defaultParams: {
            deleted: false,
            sortBy: sortOptions[0].key,
            sortDirection: sortOptions[0].direction
        },
        onUpdate: (newParams, prev, combined) => {
            const { key, paginationDirection } = newParams;
            const params = convertToDql(Object.assign(Object.assign({}, combined), { key,
                paginationDirection }));
            getPulseMonitors(params);
        }
    });
    (0, _hooks_1.useOnMount)(() => {
        updateParams({});
    });
    const handleSortUpdate = (0, react_1.useCallback)((update) => {
        updateParams(update);
    }, [updateParams]);
    const handleNameChange = (0, react_1.useCallback)((name) => {
        updateParams({ name });
    }, [updateParams]);
    const handleTypeSelect = (0, react_1.useCallback)((typeId) => {
        const typeIds = typeId === sonar_core_1.PulseMonitorType.Personal ? Object.keys(MonitorTypeTag_1.monitorParentType).map(Number) : [typeId];
        updateParams({ typeIds });
    }, [updateParams]);
    const handleAppSelect = (0, react_1.useCallback)((applicationId) => {
        updateParams({ applicationId });
    }, [updateParams]);
    const handleDeleteMonitor = (0, react_1.useCallback)((monitor) => {
        deleteMonitor({ monitorId: monitor.id });
    }, [deleteMonitor]);
    return (react_1.default.createElement(general_1.SonarCard, { className: 'monitors-table-card', noSpacing: true, title: sonar_ts_constants_1.COPY_SPECIFIC.MONITORS },
        react_1.default.createElement(antd_1.Flex, { align: 'end', className: 'filter-section', justify: 'space-between' },
            react_1.default.createElement("div", { className: 'table-filters' },
                react_1.default.createElement(antd_1.Space, { direction: 'vertical' },
                    react_1.default.createElement(text_1.SonarText, null, sonar_ts_constants_1.COPY_SPECIFIC.NAME),
                    react_1.default.createElement(general_1.SearchInput, { className: 'monitor-name-input', onChange: handleNameChange, placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.SEARCH_MONITORS })),
                react_1.default.createElement(antd_1.Space, { direction: 'vertical' },
                    react_1.default.createElement(text_1.SonarText, null, sonar_ts_constants_1.COPY_SPECIFIC.TYPE),
                    react_1.default.createElement(antd_1.Select, { allowClear: true, className: 'type-select', onChange: handleTypeSelect, options: monitorTypeOptions, placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.FILTER_BY(sonar_ts_constants_1.COPY_SPECIFIC.TYPE), showSearch: true })),
                react_1.default.createElement(antd_1.Space, { direction: 'vertical' },
                    react_1.default.createElement(text_1.SonarText, null, sonar_ts_constants_1.COPY_SPECIFIC.APP),
                    react_1.default.createElement(applications_1.AppSelect, { nameAndLabel: true, onChange: handleAppSelect, placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.FILTER_BY(sonar_ts_constants_1.COPY_SPECIFIC.APP) }))),
            react_1.default.createElement(filters_1.SortedBySelect, { defaultValue: sortOptions[0], onChange: handleSortUpdate, queryParams: queryParams, sortOptions: sortOptions })),
        react_1.default.createElement(PaginatedTable_1.PaginatedTable, { canDeleteRow: (record) => !(MonitorTypeTag_1.monitorParentType[record.monitorTypeId] === sonar_core_1.PulseMonitorType.Personal), columns: columns, id: 'monitors-table', loading: isRequested, onChange: updateParams, onDelete: handleDeleteMonitor, resource: data, rowKey: getRowKey, scroll: {
                scrollToFirstRowOnChange: true,
                x: 'max-content'
            }, sortBy: queryParams.sortBy, sortDirection: queryParams.sortDirection, sticky: true })));
};
exports.MonitorsTable = MonitorsTable;
